import errorsHandler from '@/utils/errorsHandler';
import { mapGetters } from 'vuex';

const blockSecondParam = localStorage.getItem('blockSecondParam');
const oneDay = {
  data() {
    return {
      hiddenPanel: false,
      isSelectChannel: true,
      selects: {
        commercial: null,
        channels: null,
        measurements: null,
        target: null,
        day: null,
        agency: null,
        advertiser: null,
        project: null,
        order: null,
        mediaPlan: null,
        brand: null,
        placementType: null,
      },
      selectedCommercial: null,
      selectedCommercialFromPocket: null,
      commercialItems: [],
      currentBlock: null,
      pocketList: [],

      currentCommercial: null,
      modalName: null,
      isOpenModal: null,

      posY: 0,
      posX: 0,
      clientY: 0,
      clientX: 0,

      tempCommercial: {},
      sortString: '', // rewrite var because no support for ID sort
      allDsCount: [],
      selectedCommercialRow: null,
      isLoadingTreeFromCommercial: false,
      draggingSpot: {},
      isModalBusy: false,
      prevYear: null,

      oneDayCols: ['advertiser', 'brand', 'group'],

      secondBlockParam: blockSecondParam === '1' || blockSecondParam === '2' ? +blockSecondParam : 1,

      selectedPosition: null,
      premiumPositionOptions: [
        { text: '', value: null },
        { text: '1F', value: '1F' },
        { text: '2F', value: '2F' },
        { text: '3F', value: '3F' },
        { text: '3L', value: '3L' },
        { text: '2L', value: '2L' },
        { text: '1L', value: '1L' },
      ],
      isBusy: false,
    };
  },
  computed: {
    ...mapGetters({
      isCurrentUserSH: 'getIsCurrentUserSaleshouseManager',
      isCurrentUserAMA: 'getIsCurrentUserAgencyManager',
    }),
    canEditCommercial: function () {
      return this.$checkPermissions('commercial.update');
    },
    canCreate: function () {
      return this.$checkPermissions('spot.create');
    },
    canDelete: function () {
      return this.$checkPermissions('spot.delete');
    },
    canMoveSpotUp: function () {
      return this.$checkPermissions('spot.up');
    },
    canMoveSpotDown: function () {
      return this.$checkPermissions('spot.down');
    },
    canSwapSpot: function () {
      return this.$checkPermissions('spot.swap');
    },
    grpByDays() {
      if (this.bookingOneDay?.data) return this.bookingOneDay.data?.default_plan_grps[this.selects.day] ?? 0;
      else if (this.broadcastOneDay?.data) return this.broadcastOneDay.data?.default_plan_grps[this.selects.day] ?? 0;
      else return 0;
    },
    isAdvertiserVisible() {
      return this.oneDayCols.includes('advertiser');
    },
    isBrandVisible() {
      return this.oneDayCols.includes('brand');
    },
    isGroupVisible() {
      return this.oneDayCols.includes('group');
    },
    canFixLowPriority: function () {
      return this.$checkPermissions('spot.fix_low_priority');
    },

    // set closed state if Current User is AMA and day is closed for AMA or the same for Manager SH.
    isDisabledOperations() {
      if (!this.selects.day) return true;
      if (this.bookingOneDay?.data)
        return (
          (((this.bookingOneDay.data?.settings?.is_closed & 1) === 1 && this.isCurrentUserAMA) ||
            ((this.bookingOneDay.data?.settings?.is_closed & 2) === 2 && this.isCurrentUserSH)) &&
          !this.canWorkInClosedDay
        );
      else if (this.broadcastOneDay?.data)
        (((this.broadcastOneDay.data?.settings?.is_closed & 1) === 1 && this.isCurrentUserAMA) ||
          ((this.broadcastOneDay.data?.settings?.is_closed & 2) === 2 && this.isCurrentUserSH)) &&
          !this.canWorkInClosedDay;
      else return true;
    },
  },
  watch: {
    async selectedCommercialRow() {
      if (this.selectedCommercialRow) {
        // Load POM tree when user selects commercial in oneDay table
        this.isLoadingTreeFromCommercial = true;
        this.selects.agency = null;
        this.selects.advertiser = null;
        this.selects.brand = null;
        //clear only when not this tree
        if (this.selects.mediaPlan && this.selects.mediaPlan.id !== this.selectedCommercialRow.mediaplan_id) {
          this.selectedCommercial = null;
          this.clearCommTableSelected();
          this.commercialItems = [];
          this.selects.mediaPlan = null;
        }
        if (this.selects.order && this.selects.order.id !== this.selectedCommercialRow.order_id) this.selects.order = null;
        if (this.selects.project && this.selects.project.id !== this.selectedCommercialRow.project_id) this.selects.project = null;

        if (!this.selects.project) {
          await this.$store.dispatch('GET_PROJECTS', {
            'filter[advertiser_id]': this.selectedCommercialRow.advertiser_id,
            'filter[year_id]': this.selects.day?.slice(0, 4),
            per_page: 1000,
          });
          this.selects.project = this.projects.find((el) => el.id === +this.selectedCommercialRow.project_id) || '';
        }

        if (this.selects.project && !this.selects.order) {
          await this.$store.dispatch('GET_ORDERS', {
            'filter[project_id]': this.selects.project.id,
            per_page: 1000,
          });
          this.selects.order = this.orders.find((el) => el.id === +this.selectedCommercialRow.order_id) || '';
        }

        if (this.selects.order && !this.selects.mediaPlan) {
          await this.$store.dispatch('GET_MEDIA_PLANS', {
            'filter[order_id]': this.selects.order.id,
            'filter[channel_id]': this.selects.channels.id,
            //include: 'commercials',
            per_page: 1000,
          });
          this.selects.mediaPlan = this.mediaPlans.find((el) => el.id === +this.selectedCommercialRow.mediaplan_id) || '';
          if (this.selects.mediaPlan) {
            await this.$store.dispatch('GET_MEDIA_PLANS_ID', {
              id: this.selects.mediaPlan.id,
              data: {
                include: 'commercials',
                per_page: 1000,
              },
            });
            this.loadWgrpTable();
          }
        }

        this.isLoadingTreeFromCommercial = false;
        this.updateQuery();
      }
    },

    // Filters
    async 'selects.measurements'() {
      if (!this.isSelectChannel) {
        this.selects.target = null;
        if (this.selects.measurements) {
          await this.$store.dispatch('GET_TARGET', {
            'filter[measurement_company_id]': this.selects.measurements.id,
          });
        } else {
          this.selects.target = null;
        }
        //await this.getOneDayInfo(true);
      }
    },
    async 'selects.target'() {
      if (!this.isSelectChannel) {
        //await this.getOneDayInfo(true);
      }
    },
    async 'selects.day'() {
      if (this.selects.channels) {
        await this.getOneDayInfo(true);
        this.isSelectChannel = false;
        if (this.prevYear !== this.selects.day?.slice(0, 4)) {
          if (this.selects.agency) {
            await this.$store.dispatch('GET_PROJECTS', {
              per_page: 1000,
              'filter[advertiser_id]': this.selects.advertiser?.id,
              'filter[agency_id]': this.selects.agency?.id,
              'filter[year_id]': this.selects.day?.slice(0, 4),
            });
            this.selects.project = null;
          }
        }
        this.prevYear = this.selects.day?.slice(0, 4);
      }
    },

    async 'selects.commercial'() {
      if (!this.isSelectChannel) {
        await this.getOneDayInfo(true);
      }
    },

    async 'selects.block'() {
      if (!this.isSelectChannel) {
        await this.getOneDayInfo(true);
      }
    },

    // Commercials filters
    async 'selects.agency'() {
      if (!this.isSelectChannel && !this.isLoadingTreeFromCommercial) {
        if (!this.selects.advertiser) await this.getOneDayInfo(true);
        this.selects.advertiser = null;
        this.$store.commit('clearAdvertisersList');
        if (this.selects.agency && this.selects.agency.id) {
          this.selects.project = null;
          await Promise.all([
            this.$store.dispatch('GET_ADVERTISERS', { per_page: 2000, 'filter[agency_id]': this.selects.agency ? this.selects.agency.id : null }),
            this.$store.dispatch('GET_PROJECTS', {
              per_page: 1000,
              'filter[advertiser_id]': this.selects.advertiser?.id,
              'filter[agency_id]': this.selects.agency?.id,
              'filter[year_id]': this.selects.day?.slice(0, 4),
            }),
          ]);
        }
      }
    },
    async 'selects.advertiser'() {
      if (!this.isSelectChannel && !this.isLoadingTreeFromCommercial) {
        this.selects.project = null;
        this.$store.commit('clearProjects');
        if (!this.selects.brand) await this.getOneDayInfo(true);
        this.selects.brand = null;
        this.$store.commit('clearBrandsList');
        if (this.selects.advertiser && this.selects.advertiser.id) {
          await Promise.all([
            this.$store.dispatch('GET_PROJECTS', {
              per_page: 1000,
              'filter[advertiser_id]': this.selects.advertiser?.id,
              'filter[agency_id]': this.selects.agency?.id,
              'filter[year_id]': this.selects.day?.slice(0, 4),
            }),
            this.$store.dispatch('GET_BRANDS', {
              'filter[advertiser_id]': this.selects.advertiser?.id,
              per_page: 1000,
            }),
          ]);
        }
      }
    },
    async 'selects.brand'() {
      if (!this.isSelectChannel && !this.isLoadingTreeFromCommercial) {
        await this.getOneDayInfo(true);
      }
    },
    async 'selects.placementType'() {
      if (!this.isSelectChannel) {
        await this.getOneDayInfo(true);
      }
    },
    async 'selects.project'() {
      if (!this.isSelectChannel && !this.isLoadingTreeFromCommercial) {
        //this.$store.commit('getBroadcastRequest');
        this.selects.order = null;
        this.$store.commit('clearOrders');
        this.selectedCommercial = null;
        if (this.selects.project) {
          await this.$store.dispatch('GET_ORDERS', {
            'filter[project_id]': this.selects.project.id,
            per_page: 1000,
          });
        }
        this.updateQuery();
      }
    },
    async 'selects.order'() {
      if (!this.isSelectChannel && !this.isLoadingTreeFromCommercial) {
        //this.$store.commit('getBroadcastRequest');
        this.selects.mediaPlan = null;
        this.$store.commit('clearMediaPlans');
        this.selectedCommercial = null;
        if (this.selects.order) {
          await this.$store.dispatch('GET_MEDIA_PLANS', {
            'filter[order_id]': this.selects.order.id,
            'filter[channel_id]': this.selects.channels?.id,
            //include: 'commercials',
            per_page: 1000,
          });
        }
        this.updateQuery();
      }
    },
    async 'selects.mediaPlan'() {
      if (!this.isSelectChannel && !this.isLoadingTreeFromCommercial) {
        //this.$store.commit('getBroadcastRequest');
        this.commercialItems = [];
        this.selectedCommercial = null;
        if (this.selects.mediaPlan) {
          await this.$store.dispatch('GET_MEDIA_PLANS_ID', {
            id: this.selects.mediaPlan.id,
            data: {
              include: 'commercials',
              per_page: 1000,
            },
          });
          this.loadWgrpTable();
        }
        this.updateQuery();
      }
    },
    // Load n models for selects in every row
    'modalEditMediaPlans.commercials'() {
      this.commercialItems.splice(0); // clear list on updates
      if (this.modalEditMediaPlans.commercials && this.commercialVersionTypes) {
        this.modalEditMediaPlans.commercials.forEach((el) => {
          this.commercialItems.push({
            id: el.id,
            name: el.name,
            duration: el.duration,
            version: this.commercialVersionTypes.data.find((type) => type.id === el.commercial_version_type_id)?.name,
            selected: null,
            distance: el.distance,
            double_spot_id: el.double_spot_id,
            co_brand_id: el.co_brand_id,
          });
        });
      }
    },
  },
  methods: {
    time2sec(time) {
      return parseInt(time.substring(6, 8)) + 60 * parseInt(time.substring(3, 5)) + 3600 * parseInt(time.substring(0, 2));
    },
    sec2time(sec) {
      return (
        ('00' + Math.floor(sec / 3600)).slice(-2) + ':' + ('00' + (Math.floor(sec / 60) % 60)).slice(-2) + ':' + ('00' + (Math.floor(sec / 1) % 60)).slice(-2)
      );
    },

    // Update query params after selects change
    updateQuery() {
      if (
        this.$route.query.date != this.selects.day ||
        this.$route.query.channel_id != this.selects.channels?.id ||
        this.$route.query.measurement_id != this.selects.measurements?.id ||
        this.$route.query.target_id != this.selects.target?.id ||
        this.$route.query.commercial_type_id != this.selects.commercial?.id ||
        this.$route.query.block_type_id != this.selects.block?.id ||
        this.$route.query.agency_id != this.selects.agency?.id ||
        this.$route.query.advertiser_id != this.selects.advertiser?.id ||
        this.$route.query.project_id != this.selects.project?.id ||
        this.$route.query.order_id != this.selects.order?.id ||
        this.$route.query.mediaplan_id != this.selects.mediaPlan?.id ||
        this.$route.query.brand_id != this.selects.brand?.id ||
        this.$route.query.placement_type_id != this.selects.placementType?.id ||
        this.$route.query.time_slot != this.selectedTimeSlot ||
        this.$route.query.sort != this.sortString
      ) {
        this.$router.replace({
          query: {
            ...this.$route.query,
            date: this.selects.day,
            channel_id: this.selects.channels.id,
            sort: this.sortString,
            measurement_id: this.selects.measurements?.id,
            target_id: this.selects.target?.id,
            commercial_type_id: this.selects.commercial?.id,
            block_type_id: this.selects.block?.id,
            agency_id: this.selects.agency?.id,
            advertiser_id: this.selects.advertiser?.id,
            project_id: this.selects.project?.id,
            order_id: this.selects.order?.id,
            mediaplan_id: this.selects.mediaPlan?.id,
            placement_type_id: this.selects.placementType?.id,
            brand_id: this.selects.brand?.id,
            time_slot: this.selectedTimeSlot,
          },
        });
      }
    },

    // Load props into selects on page opening
    async loadProps() {
      await this.getSelectInfo();
      let year = null;
      if (this.date && /\d{4}-\d{2}-\d{2}/.test(this.date)) {
        year = this.date.slice(0, 4);
        this.prevYear = year;
      }
      if (this.agency_id && /^\d+$/.test(this.agency_id)) {
        this.selects.agency = this.agenciesListWithAll.find((el) => el.id === +this.agency_id) || '';
        if (this.selects.agency) {
          await this.$store.dispatch('GET_ADVERTISERS', { per_page: 2000, 'filter[agency_id]': this.selects.agency ? this.selects.agency.id : null });

          if (this.advertiser_id && /^\d+$/.test(this.advertiser_id)) {
            this.selects.advertiser = this.advertisersListWithAll.find((el) => el.id === +this.advertiser_id) || '';
            if (this.selects.advertiser) {
              await Promise.all([
                this.$store.dispatch('GET_PROJECTS', {
                  per_page: 1000,
                  'filter[agency_id]': this.selects.agency ? this.selects.agency.id : null,
                  'filter[advertiser_id]': this.selects.advertiser ? this.selects.advertiser.id : null,
                  'filter[year_id]': year,
                }),
                this.$store.dispatch('GET_BRANDS', {
                  'filter[advertiser_id]': this.selects.advertiser?.id,
                  per_page: 1000,
                }),
              ]);
              if (this.brand_id && /^\d+$/.test(this.brand_id)) {
                this.selects.brand = this.brandsListWithAll.find((b) => b.id === +this.brand_id);
              }
            }
          } else {
            await this.$store.dispatch('GET_PROJECTS', {
              per_page: 1000,
              'filter[agency_id]': this.selects.agency?.id,
              'filter[year_id]': year,
            });
          }
          if (this.project_id && /^\d+$/.test(this.project_id)) {
            this.selects.project = this.projects.find((el) => el.id === +this.project_id) || '';
            if (this.selects.project) {
              await this.$store.dispatch('GET_ORDERS', {
                'filter[project_id]': this.selects.project.id,
                per_page: 1000,
              });

              if (this.order_id && /^\d+$/.test(this.order_id)) {
                this.selects.order = this.orders.find((el) => el.id === +this.order_id) || '';
                if (this.selects.order) {
                  await this.$store.dispatch('GET_MEDIA_PLANS', {
                    'filter[order_id]': this.selects.order.id,
                    'filter[channel_id]': this.channel_id && /^\d+$/.test(this.channel_id) ? this.channel_id : undefined,
                    //include: 'commercials',
                    per_page: 1000,
                  });

                  if (this.mediaplan_id && /^\d+$/.test(this.mediaplan_id)) {
                    this.selects.mediaPlan = this.mediaPlans.find((el) => el.id === +this.mediaplan_id) || '';
                    if (this.selects.mediaPlan) {
                      await this.$store.dispatch('GET_MEDIA_PLANS_ID', {
                        id: this.selects.mediaPlan.id,
                        data: {
                          include: 'commercials',
                          per_page: 1000,
                        },
                      });
                      await this.loadWgrpTable();
                    }
                  }
                }
              }
            }
          }
        }
      }

      if (this.measurement_id && /^\d+$/.test(this.measurement_id)) {
        this.selects.measurements = this.measurementsList.find((el) => el.id === +this.measurement_id);
        if (this.selects.measurements) {
          await this.$store.dispatch('GET_TARGET', {
            'filter[measurement_company_id]': this.selects.measurements.id,
          });
          if (this.target_id && /^\d+$/.test(this.target_id)) {
            this.selects.target = this.targetList.find((el) => el.id === +this.target_id);
          }
        }
      }

      if (this.commercial_type_id && /^\d+$/.test(this.commercial_type_id)) {
        this.selects.commercial = this.commercialType.find((el) => el.id === +this.commercial_type_id);
      }
      if (this.block_type_id && /^\d+$/.test(this.block_type_id)) {
        this.selects.block = this.blockTypes.find((el) => el.id === +this.block_type_id);
      }
      if (this.placement_type_id && /^\d+$/.test(this.placement_type_id)) {
        this.selects.placementType = this.placementType.find((el) => el.id === +this.placement_type_id);
      }
      if (this.time_slot) this.selectedTimeSlot = +this.time_slot || null;
      if (this.sort_mode) {
        this.sortString = this.sort_mode;
        if (this.sortString && this.sortString.slice(-1) !== ',') this.sortString += ',';
      }
      if (this.channel_id && /^\d+$/.test(this.channel_id)) {
        this.selects.channels = this.channel.find((el) => el.id === +this.channel_id);
      }
      if (this.date && /\d{4}-\d{2}-\d{2}/.test(this.date)) {
        this.selects.day = this.date;
      }
    },

    async selectChannel() {
      if (this.selects.day && this.selects.channels) {
        await this.getOneDayInfo(true);
        if (this.selects.order) {
          if (this.selects.mediaPlan && this.selects.mediaPlan.channel_id !== this.selects.channels?.id) {
            this.selects.mediaPlan = null;
            this.$store.commit('clearMediaPlans');
            this.selectedCommercial = null;
          }
          await this.$store.dispatch('GET_MEDIA_PLANS', {
            'filter[order_id]': this.selects.order.id,
            'filter[channel_id]': this.selects.channels?.id,
            //include: 'commercials',
            per_page: 1000,
          });
        }
      }
    },

    // Save user preferences for columns width
    setUserColumns(lsName) {
      const divs = document.querySelectorAll('th:not([colspan]) > div.resizable-container');
      if (divs.length > 0) {
        const colsWidth = [];
        divs.forEach((div) => {
          colsWidth.push(div.offsetWidth);
        });
        localStorage.setItem(lsName, colsWidth);
      }
    },

    // Restore user preferences
    restoreUserColumns(lsName, status) {
      if (localStorage.getItem(lsName) && status === 'success') {
        try {
          const userWidth = JSON.parse('[' + localStorage.getItem(lsName) + ']');
          const divs = document.querySelectorAll('th:not([colspan]) > div.resizable-container');
          if (divs.length > 0 && userWidth.length === divs.length) {
            divs.forEach((div, index) => (div.style.width = userWidth[index] + 'px'));
          }
        } catch (e) {
          localStorage.removeItem(lsName);
        }
      }

      //restore user shown\hidden cols
      if (localStorage.getItem('oneDayCols') && status === 'success') {
        try {
          const userCols = localStorage.getItem('oneDayCols').split(',');
          if (userCols && this.oneDayCols.toString() !== userCols.toString()) {
            this.oneDayCols = userCols;
          }
        } catch (e) {
          localStorage.removeItem('oneDayCols');
        }
      }
    },

    clearPocketTableSelected() {
      if (this.$refs.pocketTable && this.selectedCommercialFromPocket) {
        this.$refs.pocketTable.clearSelected();
      }
    },
    clearCommTableSelected() {
      if (this.$refs.selectableTableWrapper && this.selectedCommercial) {
        this.$refs.selectableTableWrapper.$refs.selectableTable.clearSelected();
      }
    },

    // Selected commercial from pocket
    onPocketRowSelected(items) {
      this.selectedCommercialFromPocket = items[0] || '';
      if (items[0]) this.clearCommTableSelected();
    },

    async delayedOneDayTableUpdate() {
      if (this.updateGridTimeoutId) {
        clearTimeout(this.updateGridTimeoutId);
      }
      this.updateGridTimeoutId = setTimeout(() => {
        this.getOneDayInfo();
        this.updateGridTimeoutId = null;
      }, this.updateGridTimeout);
    },

    async throttleAddCommercial() {
      if (this.throttleTimeoutId) {
        clearTimeout(this.throttleTimeoutId);
      }
      this.isThrottledByTimeout = true;
      this.throttleTimeoutId = setTimeout(() => {
        this.isThrottledByTimeout = false;
      }, this.throttleTimeout);
    },

    async addCommercial(block, event) {
      if (this.isThrottledByRequest || this.isThrottledByTimeout) return;
      if (!this.canCreate) return;
      if (this.isDisabledOperations) {
        this.$notify({
          type: 'error',
          title: this.$i18n.t('alert.closeDay'),
          duration: 3000,
        });
        return;
      }
      this.getPosY(event);
      if (this.selectedCommercial) {
        const blockId = block.block_id;
        if (this.selectedCommercial.double_spot_id) {
          // if it is double spot
          const formData = {
            double_spot_id: this.selectedCommercial.double_spot_id,
            position: this.selectedCommercial.selected,
            mediaplan_id: this.selects.mediaPlan.id,
          };
          await this.$store.dispatch('POST_DOUBLE_SPOT_IN_BLOCKS', {
            blockId,
            formData,
            handler: () => {
              this.$notify({
                type: 'success',
                title: this.$i18n.t('alert.addCommercials'),
              });
              this.getOneDayInfo();
              this.loadWgrpTable();
            },
            handlerError: (errors) => {
              errorsHandler(errors, this.$notify);
            },
          });
        } else {
          // if single spot
          const formData = {
            commercial_id: this.selectedCommercial.id,
            position: this.selectedCommercial.selected,
            mediaplan_id: this.selects.mediaPlan.id,
          };
          if (this.throttleTimeout > 0) {
            this.isThrottledByRequest = true;
            await this.throttleAddCommercial();
          }
          await this.$store.dispatch('POST_COMMERCIAL_IN_BLOCKS', {
            blockId,
            formData,
            handler: (res) => {
              if (res.data.warnings) {
                this.$notify({
                  type: 'warning',
                  duration: 5000,
                  title: this.$i18n.t('alert.addCommercials'),
                  text: this.$i18n.t('alert.warning') + ': ' + res.data.warnings[0] + ', ' + res.data.warnings[1],
                });
              } else {
                this.$notify({
                  type: 'success',
                  title: this.$i18n.t('alert.addCommercials'),
                });
              }
              this.buildOneDayTable(res.data);
              if (this.updateGridTimeout > 0) {
                this.delayedOneDayTableUpdate();
              } else {
                this.getOneDayInfo();
              }
              this.loadWgrpTable();
              this.isThrottledByRequest = false;
            },
            handlerError: (errors) => {
              if (errors.data.errors && errors.data.errors.duration) {
                // Not enough free time. Put in query?
                this.currentBlock = block;
                this.showModalConfirmInQuery();
              } else if (errors.data.errors && errors.data.errors.position) {
                // This prem. position has been already taken. Put in other free place\query?
                this.currentBlock = block;
                this.showModalConfirmWithoutPremium();
              } else {
                errorsHandler(errors, this.$notify);
              }
              this.isThrottledByRequest = false;
            },
          });
        }
      } else if (this.selectedCommercialFromPocket) {
        //add from pocket
        const blockId = block.block_id;

        if (this.selectedCommercialFromPocket.double_spot_id) {
          // if it is double spot
          const formData = {
            double_spot_id: this.selectedCommercialFromPocket.double_spot_id,
            position: null,
            mediaplan_id: this.selectedCommercialFromPocket.mediaplan_id,
          };
          await this.$store.dispatch('POST_DOUBLE_SPOT_IN_BLOCKS', {
            blockId,
            formData,
            handler: (res) => {
              if (res.data.warnings) {
                this.$notify({
                  type: 'warning',
                  duration: 5000,
                  title: this.$i18n.t('alert.addCommercials'),
                  text: this.$i18n.t('alert.warning') + ': ' + res.data.warnings[0] + ', ' + res.data.warnings[1],
                });
              } else {
                this.$notify({
                  type: 'success',
                  title: this.$i18n.t('alert.addCommercials'),
                });
              }
              this.removeAddedItemFromPocket();
              this.getOneDayInfo();
              this.loadWgrpTable();
            },
            handlerError: (errors) => {
              errorsHandler(errors, this.$notify);
            },
          });
        } else {
          // if single spot
          const formData = {
            commercial_id: this.selectedCommercialFromPocket.id,
            position: null,
            mediaplan_id: this.selectedCommercialFromPocket.mediaplan_id,
          };
          if (this.throttleTimeout > 0) {
            this.isThrottledByRequest = true;
            await this.throttleAddCommercial();
          }
          await this.$store.dispatch('POST_COMMERCIAL_IN_BLOCKS', {
            blockId,
            formData,
            handler: ({ data }) => {
              this.$notify({
                type: 'success',
                title: this.$i18n.t('alert.addCommercials'),
              });
              this.removeAddedItemFromPocket();
              this.buildOneDayTable(data);
              if (this.updateGridTimeout > 0) {
                this.delayedOneDayTableUpdate();
              } else {
                this.getOneDayInfo(this.lastUsed === 'day' ? 'day' : 'update');
              }
              this.loadWgrpTable();
              this.isThrottledByRequest = false;
            },
            handlerError: (errors) => {
              if (errors.data && errors.data.errors && errors.data.errors.duration) {
                // Not enough free time. Put in query?
                this.currentBlock = block;
                this.showModalConfirmInQuery();
              } else if (errors.data && errors.data.errors && errors.data.errors.position) {
                // This prem. position has been already taken. Put in other free place\query?
                this.currentBlock = block;
                this.showModalConfirmWithoutPremium();
              } else {
                errorsHandler(errors, this.$notify);
              }
              this.isThrottledByRequest = false;
            },
          });
        }
      } else {
        this.$notify({
          type: 'error',
          title: this.$i18n.t('alert.selectCommercialFirst'),
          duration: 3000,
        });
      }
    },

    showModalConfirmInQuery() {
      this.$bvModal.show('confirm-in-query');
    },
    hideModalConfirmInQuery() {
      this.$bvModal.hide('confirm-in-query');
    },

    // Add commercial in query
    async confirmInQuery() {
      if (this.isThrottledByRequest || this.isThrottledByTimeout) return;
      const blockId = this.currentBlock.block_id;
      let formData = {};
      if (this.selectedCommercial) {
        formData = {
          commercial_id: this.selectedCommercial.id,
          position: this.selectedCommercial.selected,
          mediaplan_id: this.selects.mediaPlan.id,
          force: true,
        };
      } else if (this.selectedCommercialFromPocket) {
        formData = {
          commercial_id: this.selectedCommercialFromPocket.id,
          position: null,
          mediaplan_id: this.selectedCommercialFromPocket.mediaplan_id,
          force: true,
        };
      }
      if (this.throttleTimeout > 0) {
        this.isThrottledByRequest = true;
        await this.throttleAddCommercial();
      }
      await this.$store.dispatch('POST_COMMERCIAL_IN_BLOCKS', {
        blockId,
        formData,
        handler: ({ data }) => {
          this.$notify({
            type: 'success',
            title: this.$i18n.t('alert.addCommercialInQueue'),
          });
          this.currentBlock = null;
          if (this.selectedCommercialFromPocket) {
            this.removeAddedItemFromPocket();
          }
          this.buildOneDayTable(data);
          if (this.updateGridTimeout > 0) {
            this.delayedOneDayTableUpdate();
          } else {
            this.getOneDayInfo();
          }
          this.loadWgrpTable();
          this.isThrottledByRequest = false;
        },
        handlerError: (errors) => {
          errorsHandler(errors, this.$notify);
          this.isThrottledByRequest = false;
        },
      });
      this.hideModalConfirmInQuery();
    },

    showModalConfirmWithoutPremium() {
      this.$bvModal.show('confirm-without-premium');
    },
    hideModalConfirmWithoutPremium() {
      this.$bvModal.hide('confirm-without-premium');
    },

    // Add commercial without premium position in query
    async confirmWithoutPremium() {
      if (this.isThrottledByRequest || this.isThrottledByTimeout) return;
      const blockId = this.currentBlock.block_id;
      let formData = {};
      if (this.selectedCommercial) {
        formData = {
          commercial_id: this.selectedCommercial.id,
          //position: this.selectedCommercial.selected,
          mediaplan_id: this.selects.mediaPlan.id,
          force: true,
        };
      } else if (this.selectedCommercialFromPocket) {
        formData = {
          commercial_id: this.selectedCommercialFromPocket.id,
          position: null,
          mediaplan_id: this.selectedCommercialFromPocket.mediaplan_id,
          force: true,
        };
      }
      if (this.throttleTimeout > 0) {
        this.isThrottledByRequest = true;
        await this.throttleAddCommercial();
      }
      await this.$store.dispatch('POST_COMMERCIAL_IN_BLOCKS', {
        blockId,
        formData,
        handler: ({ data }) => {
          this.$notify({
            type: 'success',
            title: this.$i18n.t('alert.addCommercialInQueue'),
          });
          this.currentBlock = null;
          if (this.selectedCommercialFromPocket) {
            this.removeAddedItemFromPocket();
          }
          this.buildOneDayTable(data);
          if (this.updateGridTimeout > 0) {
            this.delayedOneDayTableUpdate();
          } else {
            this.getOneDayInfo();
          }
          this.loadWgrpTable();
          this.isThrottledByRequest = false;
        },
        handlerError: (errors) => {
          errorsHandler(errors, this.$notify);
          this.isThrottledByRequest = false;
        },
      });
      this.hideModalConfirmWithoutPremium();
    },

    // Add class if advertiser neighbors are the same
    checkSameNeighbor(field, spot, nextSpot, prevSpot) {
      if (nextSpot) {
        if (field === 'advertiser') {
          if (spot.advertiser_id && spot.advertiser_id === nextSpot.advertiser_id) {
            return 'same-neighbor';
          }
        } else if (field === 'brand_group') {
          if (spot.brand_group_id && spot.brand_group_id === nextSpot.brand_group_id) {
            return 'same-neighbor';
          }
        }
      }
      if (prevSpot) {
        if (field === 'advertiser') {
          if (spot.advertiser_id && spot.advertiser_id === prevSpot.advertiser_id) {
            return 'same-neighbor';
          }
        } else if (field === 'brand_group') {
          if (spot.brand_group_id && spot.brand_group_id === prevSpot.brand_group_id) {
            return 'same-neighbor';
          }
        }
      }
    },

    toggleLeftPanel() {
      this.hiddenPanel = !this.hiddenPanel;
      document.getElementsByClassName('broadcast-right')[0].classList.toggle('hide-right-panel');
      document.getElementsByClassName('broadcast-left')[0].classList.toggle('hide-right-panel');
      //document.getElementsByClassName("wrapper-broadcast__loader")[0].classList.toggle("hide-right-panel");
      document.getElementById('toggle-panel-icon').classList.toggle('hide-right-panel');
    },

    showAddToPocketModal(spot, event) {
      if (!this.canDelete) return;
      if (this.isDisabledOperations) {
        this.$notify({
          type: 'error',
          title: this.$i18n.t('alert.closeDay'),
          duration: 3000,
        });
        return;
      }
      this.getPosY(event);
      this.$bvModal.show('confirm-add-to-pocket');
      this.tempCommercial = spot;
    },

    hideModalConfirmAddToPocket() {
      this.$bvModal.hide('confirm-add-to-pocket');
      this.tempCommercial = {};
    },

    showBidModal(spot, step) {
      this.auctionStep = step;
      this.spot = spot;
      this.$bvModal.show('bid-modal');
      this.bidModalUpdate = !this.bidModalUpdate;
    },

    async increaseBid(coeff) {
      const formData = {
        auction_coeff: coeff,
      };

      await this.$store.dispatch('PUT_INCREASE_BID', {
        blockId: this.spot.block_id,
        spotId: this.spot.spot_id,
        formData,
        handler: () => {
          this.$notify({
            type: 'success',
            title: this.$i18n.t('auction.bidIncreased'),
            text: this.name,
          });
          this.getOneDayInfo();
        },
        handlerError: (errors) => errorsHandler(errors, this.$notify),
      });
    },

    async confirmAddToPocket() {
      this.isModalBusy = true;
      this.pocketList.push({
        spot_id: this.tempCommercial.spot_id,
        id: this.tempCommercial.commercial_id,
        name: this.tempCommercial.commercial_name,
        duration: this.tempCommercial.duration,
        version: this.commercialVersionTypes.data.find((type) => type.id === this.tempCommercial.commercial_version_type_id)?.name,
        mediaplan_id: this.tempCommercial.mediaplan_id,
        double_spot_id: this.tempCommercial.double_spot_id,
        distance: this.tempCommercial.distance,
      });
      const tempPosY = this.posY;
      const tempPosX = this.posX;
      const tempCliY = this.clientY;
      const tempCliX = this.clientX;
      const blockId = this.tempCommercial.block_id;
      const commercialId = this.tempCommercial.spot_id;
      await this.$store.dispatch('DELETE_COMMERCIAL_IN_BLOCKS', {
        blockId,
        commercialId,
        handler: () => {
          this.getOneDayInfo();
          this.loadWgrpTable();
        },
        handlerError: (errors) => {
          errorsHandler(errors, this.$notify);
        },
      });
      this.tempCommercial = {};
      this.posY = tempPosY;
      this.posX = tempPosX;
      this.clientY = tempCliY;
      this.clientX = tempCliX;
      this.isModalBusy = false;
      this.hideModalConfirmAddToPocket();
    },

    clearPocket() {
      this.clearPocketTableSelected();
      this.selectedCommercialFromPocket = null;
      this.pocketList = [];
    },

    getPosY(event) {
      // set coordinate Y of click
      const tableScrollDiv = document.getElementById('OneDayTable__wrapper');
      const offsetpos = this.recursive_offset(tableScrollDiv);
      this.clientY = event.clientY;
      this.clientX = event.clientX;
      this.posY = event.clientY + offsetpos.y;
      this.posX = event.clientX + offsetpos.x;
    },
    recursive_offset(aobj) {
      // get coordinate Y of click in scrollable oneDay div
      let currOffset = {
        y: 0,
        x: 0,
      };
      if (aobj.scrollLeft) {
        currOffset.x = aobj.scrollLeft;
      }
      if (aobj.scrollTop) {
        currOffset.y = aobj.scrollTop;
      }
      if (aobj.offsetLeft) {
        currOffset.x -= aobj.offsetLeft;
      }
      if (aobj.offsetTop) {
        currOffset.y -= aobj.offsetTop;
      }
      return currOffset;
    },

    removeAddedItemFromPocket() {
      this.pocketList = this.pocketList.filter((el) => el.spot_id !== this.selectedCommercialFromPocket.spot_id);
      this.clearPocketTableSelected();
      this.selectedCommercialFromPocket = null;
    },

    // For table rowspan
    countSpotsInBlocks(blocks) {
      let acc = 1;
      blocks.forEach((block) => {
        acc += block.spots.length + 1;
      });
      return acc;
    },

    countBusyDuration(block_duration, spots) {
      return +block_duration - spots.reduce((acc, current) => (current.is_active ? acc + this.time2sec(current.duration) : acc + 0), 0);
    },

    async getSelectInfo() {
      await Promise.all([
        this.measurementsList.length < 1 ? this.$store.dispatch('GET_MEASUREMENTS', { per_page: 1000 }) : undefined,
        this.channel.length < 1 ? this.$store.dispatch('GET_CHANNEL', { per_page: 1000 }) : undefined,
        this.commercialType.length < 1 ? this.$store.dispatch('GET_COMMERCIAL_TYPE') : undefined,
        this.blockTypes.length < 1 ? this.$store.dispatch('GET_BLOCK_TYPES') : undefined,
        this.agenciesListWithAll.length < 1 ? this.$store.dispatch('GET_AGENCIES', { per_page: 1000 }) : undefined,
        !(this.commercialVersionTypes && this.commercialVersionTypes.length > 0) ? this.$store.dispatch('GET_COMM_VER_TYPES', { per_page: 1000 }) : undefined,
        this.placementType.length < 1 ? this.$store.dispatch('GET_PLACEMENT_TYPE', { per_page: 1000 }) : undefined,
      ]);
    },

    clearSort() {
      this.sortString = '';
      this.getOneDayInfo(true);
    },

    async upSpot(event) {
      this.getPosY(event);
      const blockId = this.selectedCommercialRow.block_id;
      const formData = this.selectedCommercialRow.spot_id;
      await this.$store.dispatch('POST_SPOT_UP', {
        blockId,
        formData,
        handler: (res) => {
          if (res.data.warnings) {
            this.$notify({
              type: 'warning',
              duration: 5000,
              title: this.$i18n.t('alert.spotUp'),
              text: this.$i18n.t('alert.warning') + ': ' + res.data.warnings[0] + ', ' + res.data.warnings[1],
            });
          } else {
            this.$notify({
              type: 'success',
              title: this.$i18n.t('alert.spotUp'),
            });
          }
          this.getOneDayInfo();
          this.loadWgrpTable();
        },
        handlerError: (errors) => {
          errorsHandler(errors, this.$notify);
        },
      });
    },

    async downSpot(event) {
      this.getPosY(event);
      const blockId = this.selectedCommercialRow.block_id;
      const formData = this.selectedCommercialRow.spot_id;
      await this.$store.dispatch('POST_SPOT_DOWN', {
        blockId,
        formData,
        handler: (res) => {
          if (res.data.warnings) {
            this.$notify({
              type: 'warning',
              duration: 5000,
              title: this.$i18n.t('alert.spotDown'),
              text: this.$i18n.t('alert.warning') + ': ' + res.data.warnings[0] + ', ' + res.data.warnings[1],
            });
          } else {
            this.$notify({
              type: 'success',
              title: this.$i18n.t('alert.spotDown'),
            });
          }
          this.getOneDayInfo();
          this.loadWgrpTable();
        },
        handlerError: (errors) => {
          errorsHandler(errors, this.$notify);
        },
      });
    },

    async clearQueue() {
      await this.$store.dispatch('DELETE_CLEAR_QUEUE', {
        id: this.selects.channels.id,
        date: { date: this.selects.day },
        handler: (res) => {
          this.$notify({
            type: 'success',
            title: this.$i18n.t('alert.queueCleared'),
          });
          this.getOneDayInfo();
          this.loadWgrpTable();
        },
        handlerError: (errors) => {
          errorsHandler(errors, this.$notify);
        },
      });
    },

    async replaceSpot() {
      await this.$store.dispatch('POST_SWAP_SPOT', {
        formData: {
          spot_id: this.selectedCommercialRow.spot_id,
          commercial_id: this.selectedCommercial.id,
          mediaplan_id: this.selects.mediaPlan.id,
        },
        handler: (res) => {
          this.$notify({
            type: 'success',
            title: this.$i18n.t('alert.spotReplaced'),
          });
          this.selectedCommercialRow = null;
          this.getOneDayInfo();
          this.loadWgrpTable();
        },
        handlerError: (errors) => {
          errorsHandler(errors, this.$notify);
        },
      });
    },

    dragSpotStart(spot, elId, e) {
      if (!this.canMoveSpotUp) return;
      const movingSpot = document.getElementById(elId);
      this.draggingSpot = {};
      e.dataTransfer.dropEffect = 'move';
      e.dataTransfer.effectAllowed = 'move';
      this.draggingSpot = spot;
      this.draggingSpot.offsetMiddle = movingSpot.offsetTop + movingSpot.offsetHeight / 2; // set middle of row as start point
      this.draggingSpot.rowHeight = movingSpot.offsetHeight;
    },

    async dropSpot(block_id, e) {
      if (this.canMoveSpotUp) {
        if (block_id === this.draggingSpot.block_id && this.draggingSpot.is_active) {
          const tableScrollDiv = document.getElementById('OneDayTable__wrapper');
          const offsetpos = this.recursive_offset(tableScrollDiv);
          const posY = e.clientY + offsetpos.y; // get position of drop
          const moveForRows = (posY - this.draggingSpot.offsetMiddle) / this.draggingSpot.rowHeight; // calc difference between start and end point (in rows)
          //const steps = parseInt(Math.abs(moveForRows)) === 0 ? Math.sign(moveForRows) * Math.ceil(Math.abs(moveForRows)) : parseInt(moveForRows); // for drop in neighborhood row => always as 1 step; MODE1: calc steps via halves
          const steps = Math.sign(moveForRows) * Math.round(Math.abs(moveForRows)); // MODE2: Always move after dropped row
          // console.log(moveForRows, 'move for N steps: ', steps);
          // console.log('- - - - - - - -\n');
          //call move spot API here
          this.getPosY(e);
          const blockId = this.draggingSpot.block_id;
          const formData = this.draggingSpot.spot_id;
          const data = { distance: Math.abs(steps) };
          const isUp = Math.sign(steps) === -1 ? true : false;
          if (steps !== 0 && steps !== 0) {
            await this.$store.dispatch(isUp ? 'POST_SPOT_UP' : 'POST_SPOT_DOWN', {
              blockId,
              formData,
              data,
              handler: (res) => {
                if (res.data.warnings) {
                  this.$notify({
                    type: 'warning',
                    duration: 5000,
                    title: isUp ? this.$i18n.t('alert.spotUp') : this.$i18n.t('alert.spotDown'),
                    text: this.$i18n.t('alert.warning') + ': ' + res.data.warnings[0] + ', ' + res.data.warnings[1],
                  });
                } else {
                  this.$notify({
                    type: 'success',
                    title: isUp ? this.$i18n.t('alert.spotUp') : this.$i18n.t('alert.spotDown'),
                  });
                }
                this.draggingSpot = {};
                this.getOneDayInfo();
                this.loadWgrpTable();
              },
              handlerError: (errors) => {
                errorsHandler(errors, this.$notify);
              },
            });
          }
        } else if (block_id !== this.draggingSpot.block_id) {
          this.$notify({
            type: 'error',
            title: this.$i18n.t('alert.differentBlock'),
            text: this.$i18n.t('alert.spotCanMoveInsideBlock'),
            duration: 3000,
          });
        }
      }
    },

    async loadWgrpTable() {
      //this.$store.commit('clearWGRPtable');
      if (this.selects.mediaPlan) {
        await this.$store.dispatch('GET_MEDIA_PLANS_WGRP_TABLE', {
          id: this.selects.mediaPlan.id,
          data: {},
        });
      }
    },

    resizeLayout() {
      // Query the element
      const resizer = document.getElementById('dragMe');
      const leftSide = resizer.previousElementSibling;
      const rightSide = document.getElementsByClassName('broadcast-right')[0]; //resizer.nextElementSibling;

      // The current position of mouse
      let x = 0;
      let y = 0;
      let leftWidth = 0;

      // Handle the mousedown event
      // that's triggered when user drags the resizer
      const mouseDownHandler = function (e) {
        // Get the current mouse position
        x = e.clientX;
        y = e.clientY;
        leftWidth = leftSide.getBoundingClientRect().width;

        // Attach the listeners to `document`
        document.addEventListener('mousemove', mouseMoveHandler);
        document.addEventListener('mouseup', mouseUpHandler);
      };

      const mouseMoveHandler = function (e) {
        // How far the mouse has been moved
        const dx = e.clientX - x;
        //const dy = e.clientY - y;

        const newLeftWidth = ((leftWidth + dx) * 100) / resizer.parentNode.getBoundingClientRect().width;
        leftSide.style.width = `${newLeftWidth}%`;

        resizer.style.cursor = 'col-resize';
        document.body.style.cursor = 'col-resize';

        leftSide.style.userSelect = 'none';
        leftSide.style.pointerEvents = 'none';

        rightSide.style.userSelect = 'none';
        rightSide.style.pointerEvents = 'none';
      };

      const mouseUpHandler = function () {
        resizer.style.removeProperty('cursor');
        document.body.style.removeProperty('cursor');

        leftSide.style.removeProperty('user-select');
        leftSide.style.removeProperty('pointer-events');

        rightSide.style.removeProperty('user-select');
        rightSide.style.removeProperty('pointer-events');

        // Remove the handlers of `mousemove` and `mouseup`
        document.removeEventListener('mousemove', mouseMoveHandler);
        document.removeEventListener('mouseup', mouseUpHandler);
      };

      // Attach the handler
      resizer.addEventListener('mousedown', mouseDownHandler);
    },

    showOrHideCol(item) {
      this.oneDayCols.includes(item) ? (this.oneDayCols = this.oneDayCols.filter((i) => i !== item)) : (this.oneDayCols = [...this.oneDayCols, item]);
      localStorage.setItem('oneDayCols', this.oneDayCols); // save user shown\hidden columns
      this.$refs.oneDayTableHeaderMenu.close(); // close context menu
    },

    async fixSpot() {
      if (!(this.selectedCommercialRow && this.selectedPosition)) return;
      this.isBusy = true;
      const blockId = this.selectedCommercialRow.block_id;
      const spotId = this.selectedCommercialRow.spot_id;
      const formData = { position: this.selectedPosition };
      await this.$store.dispatch('POST_SPOT_FIX', {
        blockId,
        spotId,
        formData,
        handler: (res) => {
          this.$notify({
            type: 'success',
            title: this.$i18n.t('alert.spotFixed') + ' ' + this.selectedPosition,
          });
          this.selectedCommercialRow = null;
          this.getOneDayInfo();
          this.loadWgrpTable();
        },
        handlerError: (errors) => {
          errorsHandler(errors, this.$notify);
        },
      });
      this.isBusy = false;
    },

    async unfixSpot() {
      if (!this.selectedCommercialRow) return;
      this.isBusy = true;
      const blockId = this.selectedCommercialRow.block_id;
      const spotId = this.selectedCommercialRow.spot_id;
      await this.$store.dispatch('POST_SPOT_UNFIX', {
        blockId,
        spotId,
        handler: (res) => {
          this.$notify({
            type: 'success',
            title: this.$i18n.t('alert.spotUnfixed'),
          });
          this.selectedCommercialRow = null;
          this.getOneDayInfo();
          this.loadWgrpTable();
        },
        handlerError: (errors) => {
          errorsHandler(errors, this.$notify);
        },
      });
      this.isBusy = false;
    },
  },
};

export default oneDay;
